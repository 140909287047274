import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { AuthGuard } from "../App";

const ReservationsPage = () => {
  const [reservations, setReservations] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const propertyId = localStorage.getItem("selectedPropertyId");
    const token = localStorage.getItem("token");

    if (propertyId && token) {
      // Fetch reservations
      axios
        .get(
          `https://backend.inyumba.co.ke/iNyumba/user/reservations/viewAll/${propertyId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          setReservations(response.data.response);
        })
        .catch((error) => {
          console.error("Error fetching reservations:", error);
        });
    }
  }, []);

  const handleViewBills = (roomId) => {
    // Set rental ID (room_id) in local storage
    localStorage.setItem("selectedRentalId", roomId);
    // Redirect to the bills page for the specific rental
    navigate("/landlord/bills");
  };

  const handleSendEvictionNotice = (userId) => {
    // Ask for confirmation before proceeding
    const isConfirmed = window.confirm(
      "Are you sure you want to continue? This will send the eviction notice."
    );

    if (isConfirmed) {
      const token = localStorage.getItem("token");

      if (token) {
        axios
          .post(
            `https://backend.inyumba.co.ke/iNyumba/admin/noticelandlordtotenant/${userId}`,
            {},
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((response) => {
            console.log("Eviction notice sent successfully", response.data);
            alert("Eviction notice sent successfully!");
          })
          .catch((error) => {
            console.error("Error sending eviction notice:", error);
            alert("Failed to send eviction notice.");
          });
      }
    } else {
      // If the user cancels the action, just log a message
      console.log("Eviction notice action cancelled.");
    }
  };

  return (
    <AuthGuard>
      <div className="container mx-auto px-4 py-8">
        <h1 className="text-3xl font-semibold mb-4">Reservations</h1>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          {reservations.map((reservation) => (
            <div
              key={reservation.room_id}
              className="bg-white shadow-md rounded-md p-4"
            >
              <p>
                <strong>User Name:</strong> {reservation.user_name}
              </p>
              <p>
                <strong>Email:</strong> {reservation.email}
              </p>
              <p>
                <strong>Phone:</strong> {reservation.phone}
              </p>
              <p>
                <strong>Entry Date:</strong> {reservation.entrydate}
              </p>
              <p>
                <strong>House Number:</strong> {reservation.house_no}
              </p>
              <p>
                <strong>Booking Status:</strong> {reservation.booking_status}
              </p>
              <p>
                <strong>Deposit Paid:</strong> {reservation.deposit_paid}
              </p>
              <p>
                <strong>Payment Status:</strong> {reservation.payment_status}
              </p>
              {/* Button for viewing bills */}
              <button
                onClick={() => handleViewBills(reservation.room_id)}
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-2"
              >
                Set Tenant Bills
              </button>

              {/* Button for sending eviction notice */}
              <button
                onClick={() => handleSendEvictionNotice(reservation.user_id)}
                className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded mt-2"
              >
                Send Eviction Notice
              </button>
            </div>
          ))}
        </div>

        {/* Add button to navigate back */}
        <button
          onClick={() => navigate(-1)}
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-8"
        >
          Go Back
        </button>
      </div>
    </AuthGuard>
  );
};

export default ReservationsPage;
